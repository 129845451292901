/* Colors Neuhaus */
/* Fonts Neuhaus */
.glider-contain {
  width: 100%;
  margin: 0 auto;
  position: relative; }

.glider {
  margin: 0 auto;
  position: relative;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }

.glider-track {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  width: 100%;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1; }

.glider.draggable {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: -webkit-grab;
  cursor: grab; }

.glider.draggable .glider-slide img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none; }

.glider.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.glider-slide {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-transition: 1s ease opacity;
  transition: 1s ease opacity;
  width: 100%;
  opacity: 0; }
  .glider-slide.visibleItem {
    opacity: 1; }

.glider-slide img {
  max-width: 100%; }

.glider::-webkit-scrollbar-track {
  background-color: #efefef; }

.glider::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #efefef; }

.glider::-webkit-scrollbar-thumb {
  background-color: #503223 !important; }

.glider-prev,
.glider-next {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  outline: none;
  background: none;
  padding: 0;
  z-index: 2;
  font-size: 40px;
  text-decoration: none;
  left: -23px;
  border: 0;
  top: 30%;
  cursor: pointer;
  color: #666;
  opacity: 1;
  line-height: 1;
  -webkit-transition: opacity 0.05s cubic-bezier(0.17, 0.67, 0.83, 0.67), color 0.05s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  transition: opacity 0.05s cubic-bezier(0.17, 0.67, 0.83, 0.67), color 0.05s cubic-bezier(0.17, 0.67, 0.83, 0.67); }

.glider-prev:hover,
.glider-next:hover,
.glider-prev:focus,
.glider-next:focus {
  color: #a89cc8; }

.glider-next {
  right: -23px;
  left: auto; }

.glider-next.disabled,
.glider-prev.disabled {
  opacity: 0.25;
  color: #666;
  cursor: default; }

.glider-hide {
  opacity: 0; }

.glider-dots {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  padding: 0; }

.glider-dot {
  border: 2px solid #fff;
  padding: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
  display: block;
  cursor: pointer;
  color: #503223;
  border-radius: 999px;
  background: #503223;
  width: 14px;
  height: 14px;
  margin: 7px;
  opacity: 0.5; }
  .glider-dot:hover, .glider-dot:focus, .glider-dot.active {
    opacity: 1; }
  .glider-dot:only-child {
    display: none; }

@media (max-width: 36em) {
  .glider::-webkit-scrollbar {
    opacity: 1;
    -webkit-appearance: none;
    width: 7px;
    height: 3px; }
  .glider::-webkit-scrollbar-thumb {
    opacity: 1;
    border-radius: 99px;
    background-color: rgba(156, 156, 156, 0.25);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.25); } }
